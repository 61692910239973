<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>disability-wheelchair-1</title>
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="9.75" cy="3.75" r="3" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M9.75,6.75v7.5a1.5,1.5,0,0,0,1.5,1.5h4.5a3,3,0,0,1,3,3v4.5h1.5"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.75,11.25h-6a6,6,0,1,0,6,6v-1.5"
    />
  </svg>
</template>
